import React, { useRef, useState, useEffect } from "react";
import { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import NewDua from "./pages/NewDua/NewDua";
import Profile from "./pages/Profile/Profile";
import Signin from "./pages/Signin/Signin";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import NotFound from "./pages/NotFound/NotFound";
import Loader from "./components/Loader/Loader";
import Overlay from "./components/Overlay/Overlay";
import { authService } from "./services/auth/auth.service";
import { messageService, menuService } from "./services/shared/shared.service";
import fetchIntercept from "fetch-intercept";
import LoadingBar from "react-top-loading-bar";
import GoogleMap from "./pages/GoogleMap/GoogleMap";
import Temp from "./pages/Temp/Temp";
import Temp2 from "./pages/Temp2/Temp2";
import AddContent from "./pages/AddContent/AddContent";
import AddContentMenu from "./pages/AddContentMenu/AddContentMenu";
import ViewContent from "./pages/ViewContent/ViewContent";

const routes = [
  {
    path: "",
    component: Dashboard,
    exact: true,
  },
  {
    path: "dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "new-dua",
    component: NewDua,
    exact: true,
  },
  {
    path: "settings",
    component: Profile,
    exact: true,
  },
  {
    path: "map",
    component: Temp2,
    exact: true,
  },
  {
    path: "categories/add",
    component: AddContentMenu,
    exact: true,
  },
  {
    path: "content/:category/add",
    component: AddContent,
    exact: true,
  },
  {
    path: "content/:category/edit/:videoId",
    component: AddContent,
    exact: true,
  },
  {
    path: "content/:category",
    component: ViewContent,
    exact: true,
  }
];

function App() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const ref = useRef(null);

  fetchIntercept.register({
    request: function (url, config) {
      startLoader();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authService.getToken()}`,
      };
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      stopLoader();
      return response;
    },

    responseError: function (error) {
      return Promise.reject(error);
    },
  });

  const startLoader = async () => {
    if (ref.current) {
      ref.current.continuousStart();
      setShowOverlay(true);
    }
  };

  const stopLoader = async () => {
    if (ref.current) {
      ref.current.complete();
      setShowOverlay(false);
    }
  };

  const verifyToken = async () => {
    authService.getToken() &&
      authService.validateAuthToken().then((result) => {
        if (result && result.success) {
          setAuthenticated(true);
          messageService.sendMessage(result.user);
        } else {
          setAuthenticated(false);
          messageService.sendMessage(null);
          authService.logout();
        }
      });
  };

  useEffect(() => {
    verifyToken();
    const subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });

    const menuSubscription = menuService.getMenu().subscribe((data) => {
      console.log('menuSubscription data', data);
    });

    return () => {
      console.log("App Destroy");
      subscription.unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/signin" isAuthenticated={isAuthenticated}>
          <Signin />
        </PublicRoute>

        <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
          <LoadingBar color="#809AE7" height={4} ref={ref} />
          <Overlay show={showOverlay} />
          <div className="flex h-screen bg-white">
            <SideNavBar />
            <ProtectedRoutes />
          </div>
        </PrivateRoute>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const ProtectedRoutes = () => (
  <Switch>
    <Suspense fallback={<Loader />}>
      {routes.map(({ component: Component, path, exact }) => (
        <Route path={`/${path}`} key={path} exact={exact}>
          <Component />
        </Route>
      ))}
    </Suspense>
  </Switch>
);

export default App;
