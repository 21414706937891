import urls from "../../constants/urls";

export const youtubeService = {
    saveMenu,
    getMenuById,
    getMenus,
    saveVideo,
    getVideoById,
    getVideosByMenuId,
    getVideos
};

function saveMenu(payload) {
    const requestOptions = {
        method: payload.menuId ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload.menu),
    };

    return fetch(urls.YOUTUBE_MENU, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("ERROR :: SAVE MENU :: ", err);
        });
}

function getMenuById() {
}

function getMenus() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(urls.YOUTUBE_MENU, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("ERROR :: GET MENUS :: ", err);
        });
}

function saveVideo(payload) {
    const requestOptions = {
        method: payload.videoId ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload.video),
    };

    return fetch(urls.YOUTUBE_VIDEO, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("ERROR :: SAVE VIDEO :: ", err);
        });
}

function getVideoById() {
}

function getVideosByMenuId(menuId) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(urls.GET_YOUTUBE_VIDEO_BY_MENU + menuId, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("ERROR :: GET Videos By MenuID :: ", err);
        });
}

function getVideos() {
}