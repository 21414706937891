import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ArrowLeftIcon,
  CheckIcon,
  LockClosedIcon,
  MenuAlt4Icon,
} from "@heroicons/react/outline";
import Swal from "sweetalert2";
import { messageService } from "./../../services/shared/shared.service";
import { authService } from "./../../services/auth/auth.service";

function Profile() {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        setUser(message);
      } else {
        setUser(null);
      }
    });

    return () => {
      console.log("Profile Destroy");
      subscription.unsubscribe();
    };
  }, []);

  const onSubmit = (data) => {
    console.log("Form: ", data);
    authService.changePassword(data).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "success",
          confirmButtonColor: "#323484",
        });
        reset();
      } else {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "warning",
          confirmButtonColor: "#323484",
        });
      }
    });
  };

  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
          Profile
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4 sm:p-8 space-y-4 flex-1 flex-col overflow-y-auto">
        {/* <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
                    <div>
                        <Link to="/">
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
                            >
                                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                                Back
                            </button>
                        </Link>
                    </div>
                </div> */}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="max-w-4xl rounded-lg border shadow-lg">
              <div
                className="h-48 bg-theme1 rounded-t-lg"
                style={{
                  backgroundImage: `url("http://thecallazanapp.com/assets/images/pattern-thecallazan2.png")`,
                }}
              ></div>
              <div className="divide-y">
                <div className="flex justify-between p-6">
                  <div className="flex flex-col items-center -mt-20">
                    <div className="rounded-full h-24 w-24 p-1 bg-white bg-gradient-to-t to-indigo-500 from-red-500">
                      <img
                        src="/mecca.png"
                        alt=""
                        className="h-full w-full rounded-full object-cover"
                      />
                    </div>
                    {/* <div className="font-bold">{user?.displayName}</div> */}
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-5 p-6">
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">Name</div>
                    <div className="text-gray-700">{user?.displayName}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">Email</div>
                    <div className="text-gray-700">{user?.email}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">Role</div>
                    <div className="text-gray-700">{user?.role}</div>
                  </div>
                  <div className="space-y-1">
                    <div className="text-sm text-gray-500">TimeZone</div>
                    <div className="text-gray-700">{user?.timeZone}</div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-5 p-6">
                  <div className="relative space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm text-gray-500"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      type="password"
                      id="password"
                      className={`${
                        errors.password ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                      {...register("password", { required: true })}
                    />
                  </div>
                  <div className="relative space-y-1">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm text-gray-500"
                    >
                      New Password
                    </label>
                    <input
                      name="newPassword"
                      type="password"
                      id="newPassword"
                      className={`${
                        errors.newPassword ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                      {...register("newPassword", { required: true })}
                    />
                  </div>

                  <div className="relative space-y-1">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm text-gray-500"
                    >
                      Confirm Password
                    </label>
                    <input
                      name="confirmPassword"
                      type="password"
                      id="confirmPassword"
                      className={`${
                        errors.confirmPassword ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                      {...register("confirmPassword", { required: true })}
                    />
                  </div>
                  <div className="col-span-2">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                    >
                      <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default Profile;
