import Temp from "../Temp/Temp";
import { LoadScript } from '@react-google-maps/api';

function Temp2() {

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA"
            libraries={["places","drawing"]}
        >
            <Temp></Temp>
        </LoadScript>
    );
}

export default Temp2;