import React, { useState, useRef, useEffect } from 'react';
import { Controller } from "react-hook-form";

function Autocomplete({ elName, control, required, addressChange }) {
    const placeInputRef = useRef(null);

    useEffect(() => {
        loadGoogleMapScript(() => {
            initPlaceAPI();
        });

        return () => {
            console.log('Destroying Autocomplete');

            // Removing Google Map Script
            const scriptList = document.querySelectorAll("script[type='text/javascript']");
            const convertedNodeList = Array.from(scriptList);
            const mapScript = convertedNodeList.find(script => script.id === "google-map");
            if (mapScript) {
                // mapScript.parentNode.removeChild(mapScript);
                mapScript.remove();
            }

            // Removing HTML
            const elems = document.getElementsByClassName("pac-container");
            console.log(elems);
            // element.parentNode.removeChild(element);
            // elems.forEach(element => element.remove());
            if (elems && elems.length > 0) {
                for (var i = 0; i < elems.length; i++) {
                    elems[i].remove();
                }
                
            }
        };
    }, []);

    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.id = 'google-map';
            googleMapScript.type = 'text/javascript';
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener("load", callback);        
        }
    }

    const initPlaceAPI = () => {
        let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);
        new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
            let place = autocomplete.getPlace();
            addressChange({
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
        });
    };
    return (
        <Controller
            control={control}
            name={elName}
            rules={{ required: required }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <input className="py-2 pl-4 pr-8 rounded border w-full focus:outline-none text-sm focus:border-gray-500 text-gray-700"
                    type="text"
                    ref={placeInputRef}
                    placeholder="Enter Address"
                    // onChange={onChange}
                />
            )}
        />
    );
}

export default Autocomplete;