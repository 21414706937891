import React, { useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function GoogleMap() {
    const [map, setMap] = useState({
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    });

    useEffect(() => {

        return () => {
        };
    }, []);

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <div style={{ height: '60vh', width: '60%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA' }}
                    defaultCenter={map.center}
                    defaultZoom={map.zoom}
                >
                    <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
}

export default GoogleMap;
