import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuAlt4Icon } from "@heroicons/react/outline";
import { InboxIcon } from "@heroicons/react/solid";
import { duaService } from "../../services/dua/dua.service";

function Dashboard() {
  const [duaList, setDuaList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    duaService.getDuaList().then((res) => {
      if (res.success) {
        setDuaList(res.duas);
        setFilteredList(res.duas);
      }
    });
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = duaList.filter((dua) => {
        return dua.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFilteredList(results);
    } else {
      setFilteredList(duaList);
    }
  };

  const parseFileNameFromUrl = (url) => {
    const splitedurl = url.split("/");
    return splitedurl && splitedurl[splitedurl.length - 1];
  };

  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4">
          Dashboard
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4 sm:p-8 space-y-4 flex-1 overflow-y-auto">
        <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
          <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <div className="relative">
              <input
                type="text"
                className="w-64 text-sm rounded"
                placeholder="Search ..."
                onChange={filter}
              />
            </div>
          </div>
          <div>
            <Link to="/new-dua">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
              >
                <InboxIcon className="-ml-1 mr-2 h-5 w-5" />
                New Dua
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border border-gray-100 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-100">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 w-16 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        SR NO
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Audio URL
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        PDF URL
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Status
                      </th>
                      {/* <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredList.map((dua) => (
                      <tr key={dua._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {dua.position}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {dua.name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-theme1 underline cursor-pointer">
                            <a
                              href={dua.audioLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {parseFileNameFromUrl(dua.audioLink)}
                            </a>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-theme1 underline cursor-pointer">
                            <a
                              href={dua.pdfLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {parseFileNameFromUrl(dua.pdfLink)}
                            </a>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {dua.status}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                 <a href="#" className="text-theme1">
                                                        Edit
                                                    </a> 
                                                </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
