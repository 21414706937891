import { Controller, useForm } from "react-hook-form";
import Flatpickr from "react-flatpickr";

function DatePicker({ elName, control,options, required }) {
    return (
        <Controller
            control={control}
            name={elName}
            rules={{ required: required }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Flatpickr
                    className="w-full text-sm rounded"
                    name={name}
                    // data-enable-time
                    options={{ dateFormat: 'm/d/y h:m',...options }}
                    value={value}
                    onChange={onChange}
                   
                />
            )}
        />
    );
}

export default DatePicker;