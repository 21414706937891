require('dotenv').config()

export const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL; 
export const API_URL = process.env.REACT_APP_SERVER_API_URL;

const urls = {
    AUTH_SIGNIN : API_URL + "auth/signin",
    AUTH_ME : API_URL + "users/me",
    USER_CHANGE_PASSWORD : API_URL + "changePassword",
    DUA_URL : API_URL + 'dua',

    YOUTUBE_MENU: API_URL + 'youtubeMenus',
    YOUTUBE_VIDEO: API_URL + 'youtubeVideo',
    GET_YOUTUBE_VIDEO_BY_MENU: API_URL + 'youtubeVideo/byMenu/'
}

export default urls;