import React, { useState, useEffect } from 'react';
import {
    ArrowLeftIcon,
    CheckIcon,
    MenuAlt4Icon,
    SaveAsIcon,
    UserAddIcon,
    PlusIcon,
    PlusCircleIcon
} from "@heroicons/react/outline";

import {
    PlayIcon
} from "@heroicons/react/solid";

import { Link, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import SideNavBar from '../../components/SideNavBar/SideNavBar'
import { duaService } from '../../services/dua/dua.service';
import { youtubeService } from '../../services/youtube/youtube.service';
import moment from 'moment'
import VideoPlayerModal from '../../modals/VideoPlayerModal/VideoPlayerModal'

function ViewContent() {
    const videoList = [
        {
            id: 1,
            title: 'JavaScript Tutorial for Beginners',
            url: 'https://youtu.be/7eh4d6sabA0',
            status: true,
            description: '',
            uploadDate: '30-12-2021'
        },
        {
            id: 2,
            title: 'JavaScript Tutorial for Beginners',
            url: 'https://youtu.be/BBpAmxU_NQo',
            status: true,
            description: '',
            uploadDate: '30-12-2021'
        },
        {
            id: 3,
            title: 'JavaScript Tutorial for Beginners',
            url: 'https://youtu.be/4RLhuZ3N9nc',
            status: true,
            description: '',
            uploadDate: '30-12-2021'
        },
        {
            id: 4,
            title: 'JavaScript Tutorial for Beginners',
            url: 'https://youtu.be/-_X6PhkjpzU',
            status: true,
            description: '',
            uploadDate: '30-12-2021'
        },
        {
            id: 5,
            title: 'JavaScript Tutorial for Beginners',
            url: 'https://youtu.be/W6NZfCO5SIk',
            status: true,
            description: '',
            uploadDate: '30-12-2021'
        }
    ];
    const { category } = useParams();
    const [videos, setVideos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [videoUrlModal, setvideoUrlModal] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const onSubmit = data => {
        console.log('onSubmit');
        console.log(data);
        //Call Http Service
        //Update SideNav
        reset();
    };

    const save = () => {
        console.log('save');
    }

    function toggleVideoModal(videoUrl, videoTitle) {
        console.log('showVideoModal', videoUrl);
        setvideoUrlModal(videoUrl);
        setVideoTitle(videoTitle);
        setOpenModal(true);
    }

    function hideVideoModal() {
        console.log('hide');
        setOpenModal(false);
    }

    function getVideoId(videoUrl) {
        // console.log('Video Url', videoUrl);
        const url = new URL(videoUrl);
        const videoId = url.searchParams.get('v');
        return videoId;
    }

    function toggleStatus(e, videoId) {
        console.log(e.target.checked);
        const payload = {
            videoId: videoId,
            video: {
                _id: videoId,
                status: e.target.checked ? 'active' : 'inActive'
            }
        }
        console.log(payload);

        // videos.map(video => {
        //     console
        // })

        // youtubeService.saveVideo(payload)
        //     .then(data => {
        //         console.log('Response: Update Status', data)
        //         if (data.success && data.videos) {
        //             setVideos(data.videos);
        //         }
        //     });
    }

    useEffect(() => {
        console.log('Mount ViewContent');

        youtubeService.getVideosByMenuId(category)
            .then(data => {
                console.log('Response: Videos By MenuID', data)
                if (data.success && data.videos) {
                    setVideos(data.videos);
                }
            });

        return () => {
            console.log("Unmount ViewContent");
        };
    }, [category]);

    return (
        <main className="flex flex-1 flex-col w-full overflow-hidden">
            <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
                <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
                    Videos
                </div>
                <div className="h-full lg:hidden">
                    <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
                        <MenuAlt4Icon className="h-6 w-6" />
                    </button>
                </div>
            </div>
            <div className="p-4 sm:p-8 space-y-4 flex-1 flex-col overflow-y-auto">
                <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
                    <div>
                        <Link to={`/content/${category}/add`}>
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
                            >
                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                                Add
                            </button>
                        </Link>
                    </div>
                </div>
                {/* 8JJ101D3knE */}
                <div className="w-auto">
                    <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
                        {
                            videos.map((video) =>
                                <div key={video._id} className="cursor-pointer">

                                    <div className="w-full h-72 bg-gray-200  rounded-md overflow-hidden lg:h-50 relative group">
                                        {/* <img src="https://i.ytimg.com/vi/wUeNqhTWlRE/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCrjJvF33399KkOTHxjXjmOWubvxg" alt="Front of men&#039;s Basic Tee in black." className="w-full h-full object-center object-cover lg:w-full lg:h-full">
                                        </img> */}
                                        <img src={`http://img.youtube.com/vi/${getVideoId(video.url)}/0.jpg`} className="w-full h-full object-center object-cover lg:w-full lg:h-full relative">
                                        </img>
                                        <div className="hidden absolute inset-0  group-hover:flex justify-center items-center group-hover:bg-gray-50 group-hover:bg-opacity-50 group-hover:bg-gray-500">
                                            <PlayIcon onClick={() => toggleVideoModal(video.url, video.title)} className="h-12 outline" />
                                            {/* <svg  xmlns="http://www.w3.org/2000/svg" className="h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg> */}
                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                <input type="checkbox" onChange={(e) => toggleStatus(e, video._id)} checked={video.status == 'active' ? true : false } name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                                                <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                                            </div>
                                            {/* <label for="toggle" class="text-xs text-gray-700">Toggle me.</label> */}
                                        </div>
                                    </div>
                                    <div className="mt-2 flex justify-between">
                                        <div>
                                            <h3 className="text-sm text-gray-700">
                                                {video.title}
                                                {/* <a href="#">
                                                    <span aria-hidden="true" className="absolute inset-0"></span>
                                                    {video.title}
                                                </a> */}
                                            </h3>
                                            <p className="mt-1 text-sm text-gray-500">{moment(video.createdAt).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* https://www.youtube.com/embed/tgbNymZ7vqY */}
            {openModal && <VideoPlayerModal videoId={getVideoId(videoUrlModal)} videoTitle={videoTitle} closeModal={hideVideoModal}  ></VideoPlayerModal>}
        </main>
    );
}

export default ViewContent;