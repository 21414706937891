
import { Link } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import { authService } from '../../services/auth/auth.service';
import { useHistory } from "react-router-dom";
import { messageService } from "./../../services/shared/shared.service";
import Swal from 'sweetalert2'

function Signin() {
    const history = useHistory();
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        authService.login(data.email, data.password)
            .then(
                result => {
                    if (result && result.success) {
                        authService.saveToken(result.token);
                        messageService.sendMessage(result.user)
                        history.push("dashboard");
                    } else {
                        Swal.fire({
                            title: "Alert!",
                            text: result && result.message,
                            icon: "warning",
                            confirmButtonColor: "#323484",
                          });
                    }

                }

            );
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-24 w-auto"
                        src="mecca.png"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        The Call Azan
                    </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme1 focus:border-theme1 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                                {...register("email")}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme1 focus:border-theme1 focus:z-10 sm:text-sm"
                                placeholder="Password"
                                {...register("password")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between hidden">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 text-theme1 focus:ring-theme1/90 border-gray-300 rounded"
                            />
                            <label
                                htmlFor="remember-me"
                                className="ml-2 block text-sm text-gray-900"
                            >
                                Remember me
                            </label>
                        </div>
                        <div className="text-sm">
                            <Link to="/forgot" className="font-medium text-theme1 hover:text-theme1/90">
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <LockClosedIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                />
                            </span>
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Signin;