import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ArrowLeftIcon,
  CheckIcon,
  DocumentIcon,
  MenuAlt4Icon,
  MusicNoteIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { duaService } from "../../services/dua/dua.service";
import { useHistory } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";

function NewDua() {
  const history = useHistory();
  const [place, setPlace] = useState(null);
  
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { 'date': new Date() } });


  useEffect(() => {

  }, []);

  const onAddressChange = (place) => {
    console.log(place);
    setPlace(place);
    setValue('address', place, { shouldValidate: true });
  }

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA',
    onPlaceSelected: (place) => console.log(place),
    options: {
      types: ["establishment"],
      // componentRestrictions: { country: "us" }
    }
  })

  const onSubmit = (data) => {
    console.log('Form :', data);
    data.position = 2
    data.audioFile = data.audio[0];
    data.pdfFile = data.pdf[0];

    duaService.saveDua(data)
      .then(data => {
        if (data.success) {
          reset();
          history.push("dashboard");
          console.log('New Dua Saved: ', data);
        }
      }
      );
  };

  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
          New Dua
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4 sm:p-8 space-y-4 flex-1 flex-col overflow-y-auto">
        <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
          <div>
            <Link to="/">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
              >
                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                Back
              </button>
            </Link>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="divide-y">
            <div className="max-w-4xl rounded-lg border shadow-lg p-6">
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 sm:col-span-6">
                  <div className="relative space-y-1">
                    <label
                      htmlFor="duaTitle"
                      className="block text-sm text-gray-500"
                    >
                      Dua Title
                    </label>
                    <input
                      name="name"
                      type="text"
                      id="duaTitle"
                      // className={`${errors.name
                      //     ? "ring-2 ring-red-500"
                      //     : ""} w-full text-sm rounded`}
                      className="w-full text-sm rounded"
                      {...register("name", { required: true })}
                    />
                    {errors?.name?.type === "required" && (
                      <p className="text-red-500">This is required</p>
                    )}
                  </div>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <div className="relative space-y-1">
                    <label
                      htmlFor="duaTitle"
                      className="block text-sm text-gray-500"
                    >
                      Publish Date
                    </label>
                    <DatePicker elName={'date'} control={control} options={{ enableTime:true }} required={true}  />
                    {errors?.date?.type === "required" && (
                      <p className="text-red-500">This is required</p>
                    )}
                  </div>
                </div>
                {/* <div className="col-span-12 sm:col-span-6">
                  <div className="relative space-y-1">
                    <label
                      htmlFor="duaTitle"
                      className="block text-sm text-gray-500"
                    >
                      Address
                    </label>
                    <Autocomplete elName={'address'} control={control} required={true} addressChange={(place) => onAddressChange(place)} />
                    {errors?.address?.type === "required" && (
                      <p className="text-red-500">This is required</p>
                    )}
                     <input className="py-2 pl-4 pr-8 rounded border w-full focus:outline-none text-sm focus:border-gray-500 text-gray-700"
                      type="text"
                      ref={ref}
                      placeholder="Enter Address"
                    /> 
                  </div>
                </div> */}
                <div className="col-span-12 sm:col-span-6">
                  <div className="relative space-y-1">
                    <label
                      htmlFor="duaStatus"
                      className="block text-sm text-gray-500"
                    >
                      Status
                    </label>
                    <select
                      name="duaStatus"
                      id="duaStatus"
                      className="w-full text-sm rounded"
                      {...register("status")}
                    >
                      <option value="active" defaultValue>
                        Active
                      </option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="space-y-1">
                    <label
                      htmlFor="duaStatus"
                      className="block text-sm text-gray-500"
                    >
                      Audio
                    </label>
                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-4 text-center">
                        <MusicNoteIcon className={`${watch("audio")?.[0] ? 'text-indigo-400' : 'text-gray-400'} mx-auto h-20 w-20`} />


                        {watch("audio")?.[0] ?
                          <div className="flex text-white-600 rounded py-2 px-4 items-center shadow-md bg-indigo-100 space-x-4">
                            <span className="text-sm text-gray-500">{watch("audio")[0].name}</span>
                            {/* text-indigo-600 */}
                            <span onClick={() => setValue('audio', '')} className="cursor-pointer">
                              {/* Remove */}
                              <XIcon className="h-5 w-5 text-theme1" />
                            </span>
                          </div> :
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="audio"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="audio"
                                name="audio"
                                type="file"
                                className="sr-only"
                                {...register("audio", { required: true })}
                                // onChange={(e) => setAudioFile(e.target.files[0])}
                                accept="audio/*"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                        }
                      </div>
                    </div>
                    {errors?.audio?.type === "required" && (
                      <p className="text-red-500 text-sm">This is required</p>
                    )}
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="space-y-1">
                    <label
                      htmlFor="duaStatus"
                      className="block text-sm text-gray-500"
                    >
                      PDF
                    </label>
                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-4 text-center">
                        <DocumentIcon className={`${watch("pdf")?.[0] ? 'text-indigo-400' : 'text-gray-400'} mx-auto h-20 w-20`} />


                        {watch("pdf")?.[0] ?
                          <div className="flex text-white-600 rounded py-2 px-4 items-center shadow-md bg-indigo-100 space-x-4">
                            <span className="text-sm text-gray-500">{watch("pdf")[0].name}</span>
                            {/* text-indigo-600 */}
                            <span onClick={() => setValue('pdf', '')} className="cursor-pointer">
                              {/* Remove */}
                              <XIcon className="h-5 w-5 text-theme1" />
                            </span>
                          </div> :
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="pdf"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="pdf"
                                name="pdf"
                                type="file"
                                className="sr-only"
                                // onChange={(e) => setPdfFile(e.target.files[0])}
                                {...register("pdf", { required: true })}
                                accept="application/pdf"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                        }
                      </div>
                    </div>
                    {errors?.pdf?.type === "required" && (
                      <p className="text-red-500 text-sm">This is required</p>
                    )}
                  </div>
                </div>
                <div className="col-span-12">
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                  >
                    <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                    Save Dua
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default NewDua;
