import { BehaviorSubject } from 'rxjs';

const subject =  new BehaviorSubject(null)
const menuSubject = new BehaviorSubject(null);

export const messageService = {
    sendMessage: message => subject.next(message),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};

export const menuService = {
    updateMenu: menu => menuSubject.next(menu),
    clearMenu: () => menuSubject.next(),
    getMenu: () => menuSubject.asObservable()
};

export const videoService = {
    updateVideoList: menu => menuSubject.next(menu),
    clearVideoList: () => menuSubject.next(),
    getVideoList: () => menuSubject.asObservable()
};