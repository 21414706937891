import React, { useState, useEffect } from 'react';
import {
    CogIcon,
    HomeIcon,
    LogoutIcon,
    XIcon
} from "@heroicons/react/outline";
import { InboxIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { authService } from '../../services/auth/auth.service';
import { youtubeService } from '../../services/youtube/youtube.service';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import { messageService, menuService } from "../../services/shared/shared.service";

function SideNavBar() {
    const [menuLinks, setMenuLinks] = useState([]);

    const history = useHistory();

    function onLogout() {
        Swal.fire({
            title: 'Alert!',
            text: "Are you sure you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#323484',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                messageService.sendMessage(null);
                authService.logout();
                history.push("/signin");
            }
        })

    }

    useEffect(() => {
        const menuSubscription = menuService.getMenu().subscribe((data) => {
            console.log('Menus', data);
            if (data) {
                setMenuLinks(menuLinks => [...menuLinks, data]);
            }
        });

        youtubeService.getMenus()
            .then(data => {
                if (data.success && data.menus) {
                    console.log('Menu:', data);
                    setMenuLinks(data.menus);
                }
            });

        return () => {
            console.log("App Destroy");
            menuSubscription.unsubscribe();
        };
    }, []);

    return (
        <nav className="w-64 border-r h-full bg-theme1 divide-y divide-gray-50 divide-opacity-20 lg:flex flex-col z-50 hidden flex-shrink-0 overflow-hidden fixed lg:static" style={{
            backgroundImage: `url("http://thecallazanapp.com/assets/images/pattern-thecallazan2.png")`
        }}>
            <div className="h-16 flex items-center px-4">
                <div className="flex justify-between items-center w-full">
                    <div>
                        <NavLink to="/">
                            <img
                                src="http://thecallazanapp.com/assets/images/azan-logowhite.png"
                                className="h-8 w-full object-scale-down"
                                alt="Logo"
                            />
                        </NavLink>
                    </div>
                    <div className="lg:hidden">
                        <button className="p-1 flex items-center justify-center rounded outline-none focus:outline-none">
                            <XIcon className="h-6 w-6 text-white" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-4 py-8 overflow-y-auto flex flex-col flex-1 justify-between">
                <ul className="space-y-3">
                    <li>
                        <NavLink to="/dashboard" activeClassName="nav-item-active" className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer ">
                            <div>
                                <HomeIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">Dashboard</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/new-dua" activeClassName="nav-item-active" className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer ">
                            <div>
                                <InboxIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">New Dua</div>
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/settings" activeClassName="nav-item-active" className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer ">
                            <div>
                                <CogIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">Settings</div>
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to="/map" activeClassName="nav-item-active" className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer ">
                            <div>
                                <CogIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">Google Map</div>
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to="/add-category">
                            <div className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer">
                                <div>
                                    <InboxIcon className="h-5 w-5" />
                                </div>
                                <div className="font-medium text-sm">Add Category</div>
                            </div>
                        </NavLink>
                    </li> */}
                    {
                        menuLinks.map((menu) =>
                            <li key={menu._id}>
                                <NavLink to={`/content/${menu._id}`}>
                                    <div className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer">
                                        <div>
                                            <InboxIcon className="h-5 w-5" />
                                        </div>
                                        <div className="font-medium text-sm">{menu.title}</div>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    }
                </ul>
                <ul className="space-y-3 mt-3">
                    <li>
                        <NavLink to="/categories/add">
                            <div className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer">
                                <div>
                                    <InboxIcon className="h-5 w-5" />
                                </div>
                                <div className="font-medium text-sm">Add Category</div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings" activeClassName="nav-item-active" className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer ">
                            <div>
                                <CogIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">Settings</div>
                        </NavLink>
                    </li>
                    <li>
                        <div onClick={() => onLogout()} className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer">
                            <div>
                                <LogoutIcon className="h-5 w-5" />
                            </div>
                            <div className="font-medium text-sm">Log Out</div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default SideNavBar;