import urls from "../../constants/urls";

export const duaService = {
    saveDua,
    getDuaList
};

function getDuaList() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(urls.DUA_URL, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("Get Dua Error ", err);
        });
}

// Add/Edit
function saveDua(dua) {
    let formData = new FormData();
    formData.append('name', dua.name);
    formData.append('position', dua.position);
    formData.append('audioFile', dua.audioFile);
    formData.append('pdfFile', dua.pdfFile);
    formData.append('status', dua.status);

    const requestOptions = {
        method: 'POST',
        body: formData
    };

    return fetch(urls.DUA_URL, requestOptions)
        .then(response => response.json())
        .catch(err => {
            console.log("Save Dua Error ", err);
        });
}