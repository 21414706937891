import React, { useState, useEffect } from 'react';
import {
    ArrowLeftIcon,
    CheckIcon,
    MenuAlt4Icon
} from "@heroicons/react/outline";
import { Link, useParams, useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import SideNavBar from '../../components/SideNavBar/SideNavBar'
import { youtubeService } from '../../services/youtube/youtube.service';
import { videoService } from "./../../services/shared/shared.service";

function AddContent() {
    const { category } = useParams();
    const history = useHistory();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const onSubmit = data => {
        console.log('Form ', data);
        data.menuId = category
        const payload = {
            videoId: '',
            video: data
        }

        youtubeService.saveVideo(payload)
            .then(data => {
                if (data.success && data.menu) {
                    history.push(`/content/${category}`);
                }
            });
        reset();
    };

    return (
        <main className="flex flex-1 flex-col w-full overflow-hidden">
            <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
                <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
                    Add Content
                </div>
                <div className="h-full lg:hidden">
                    <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
                        <MenuAlt4Icon className="h-6 w-6" />
                    </button>
                </div>
            </div>
            <div className="p-4 sm:p-8 space-y-4 flex-1 flex-col overflow-y-auto">
                <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
                    <div>
                        <Link to={`/content/${category}`}>
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
                            >
                                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                                Back
                            </button>
                        </Link>
                    </div>
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} className="divide-y">
                        <div className="flex flex-wrap max-w-5xl py-8">
                            <div className="w-full lg:w-1/3">
                                <div className="text-lg font-medium mb-4">
                                    Video Information
                                </div>
                            </div>
                            <div className="w-full lg:w-2/3">
                                <div className="grid grid-cols-12 gap-5">
                                    <div className="col-span-12 sm:col-span-6">
                                        <div className="relative space-y-1">
                                            <label
                                                htmlFor="duaTitle"
                                                className="block text-sm text-gray-500"
                                            >
                                                Video Title
                                            </label>
                                            <input
                                                name="name"
                                                type="text"
                                                id="title"
                                                // className={`${errors.name
                                                //     ? "ring-2 ring-red-500"
                                                //     : ""} w-full text-sm rounded`}
                                                className="w-full text-sm rounded"
                                                {...register("title", { required: true })}
                                            />
                                            {errors?.title?.type === "required" && <p className="text-red-500">This is required</p>}
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <div className="relative space-y-1">
                                            <label
                                                htmlFor="duaTitle"
                                                className="block text-sm text-gray-500"
                                            >
                                                Video URL
                                            </label>
                                            <input
                                                name="name"
                                                type="text"
                                                id="duaTitle"
                                                // className={`${errors.name
                                                //     ? "ring-2 ring-red-500"
                                                //     : ""} w-full text-sm rounded`}
                                                className="w-full text-sm rounded"
                                                {...register("url", { required: true })}
                                            />
                                            {errors?.url?.type === "required" && <p className="text-red-500">This is required</p>}
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                        <div className="relative space-y-1">
                                            <label
                                                htmlFor="duaStatus"
                                                className="block text-sm text-gray-500"
                                            >
                                                Status
                                            </label>
                                            <select
                                                name="duaStatus"
                                                id="duaStatus"
                                                className="w-full text-sm rounded"
                                            {...register("status")}
                                            >
                                                <option value="active" defaultValue>
                                                    Active
                                                </option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-span-12">
                                        <div className="relative space-y-1">
                                            <label
                                                htmlFor="duaStatus"
                                                className="block text-sm text-gray-500"
                                            >
                                                Description
                                            </label>
                                            <textarea className="h-24 resize-none text-sm rounded w-full" {...register("description")}></textarea>
                                            {errors?.description?.type === "required" && <p className="text-red-500">This is required</p>}
                                        </div>
                                    </div>
                                    <div className="col-span-12">
                                        <button
                                            type="submit"
                                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                                        >
                                            <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    );
}

export default AddContent;