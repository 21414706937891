import urls from "../../constants/urls";

const login = (email, password) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/json' },
    body: formData,
  };

  return fetch(urls.AUTH_SIGNIN, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("Signin Error ", err);
    });
};

const changePassword = (payload) => {
  const requestOptions = {
    method: "POST",
      headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetch(
    urls.USER_CHANGE_PASSWORD,
    requestOptions
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log("Change Password Error ", err);
    });
};

const validateAuthToken = () => {
  const requestOptions = { headers: { "Content-Type": "application/json" } };
  return fetch(urls.AUTH_ME, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("Me Request Error ", err);
    });
};

const saveToken = (token) => {
  localStorage.setItem("token", token);
};
const logout = () => {
  localStorage.removeItem("token");
};

const getToken = () => {
  return localStorage.getItem("token");
};

export const authService = {
  login,
  validateAuthToken,
  changePassword,
  logout,
  getToken,
  saveToken,
};
