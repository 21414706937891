import React from 'react';
import YouTube from 'react-youtube';

function VideoPlayerModal(props) {
    const { closeModal, videoId, videoTitle } = props;

    console.log('mm ', videoId);



    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
                <div className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full ">
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="flex justify-between items-center border-b pb-2 ">
                            <div className="font-medium text-lg text-gray-900">
                                {videoTitle}
                            </div>
                            <div onClick={closeModal} className="cursor-pointer">
                                <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center mt-4">
                            <div className="h-auto">
                                {/* <iframe height="500px" width="650px"
                                    src={videoUrl}>
                                </iframe> */}
                                <YouTube opts={{
                                    playerVars: {
                                        autoplay: 1,
                                    }
                                }} videoId={videoId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default VideoPlayerModal;